
<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="profile_invite__edit__container">
                <div class="profile_invite__edit__container-title">担当者を削除</div>
                <form class="horizontal" @submit="onSubmit">
                  <!-- email -->
                  <div class="form-ctrl">
                    「{{ state.member.email }}」の担当者を削除しますか？
                  </div>
                  <!-- profile_ai_description -->
                  <div class="profile_invite__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >キャンセル</button>
                    <button
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                      class="btn-blue btn-md"
                    >
                      <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                      削除する
                    </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/organization-profile-delete-member/OrganizationProfileDeleteMember.ts" />
<link type="scss" scoped src="@/presentation/components/modals/organization-profile-delete-member/OrganizationProfileDeleteMember.scss" />
