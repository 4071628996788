<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="profile_reinvite__edit__container">
                <div class="profile_reinvite__edit__container-title">オーナーを変更</div>
                <div class="alert_chage_owner">
                  <p>オーナーのみの機能は以下の通りです。</p>
                  <ul class="alert_chage_owner__authority">
                    <li>協働終了後の評価機能</li>
                    <li>オーナー権限の変更機能</li>
                  </ul>
                  <p class="alert_messege">※ 必要に応じて、前任のオーナーから引き継ぎをしてもらうようお願いいたします。</p>
                </div>
                <form class="horizontal" @submit="onSubmit">
                  <div class="form-ctrl">
                    <div class="form-group">
                      <select
                          v-model="values.new_owner_id"
                          :class="{ invalid: !values.new_owner_id }"
                          class="select select_center"
                      >
                        <option value='' disabled selected style='display:none;'>お選びください</option>
                        <option
                            v-for="(row, i) in ownerCandidateMember"
                            :key="i"
                            :value="row.id"
                        >
                        {{ row.family_name + row.given_name }}さん
                        </option>
                      </select>
                      <template
                        v-if="state.errorValidateMessage"
                      >
                        <ErrorMessage
                          v-if="state.errorValidateMessage['message']"
                          :message="state.errorValidateMessage['message']"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                  <!-- profile_ai_description -->
                  <div class="profile_reinvite__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >キャンセル</button>
                    <button
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                      class="btn-blue btn-md"
                    >
                      <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                      変更する
                    </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/organization-change-owner/OrganizationChangeOwner.ts" />
<link type="scss" scoped src="@/presentation/components/modals/organization-change-owner/OrganizationChangeOwner.scss" />