import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import Input from "@/presentation/components/input/Input.vue";
import * as agh from "agh.sprintf";
export default defineComponent({
    name: "OrganizationProfileInviteMember",
    components: {
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "field"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var _b = useForm({}), handleSubmit = _b.handleSubmit, values = _b.values, setValues = _b.setValues, errors = _b.errors, isSubmitting = _b.isSubmitting;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            field: props.field,
            errorValidateMessage: {},
        });
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var email = useField("email", yup
            .string()
            .required(state.aLang.validation_required)
            .email(state.aLang.validation_format_email)
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var invite_user_type = useField("invite_user_type", yup
            .number()).value;
        var onSubmit = handleSubmit(function () {
            if (invite_user_type.value == 1) {
                return organizationInteractor
                    .inviteMember(email.value)
                    .then(function (result) {
                    emit('getListMembers');
                    emit('regist');
                    emit('close');
                }, function (error) {
                    if (error.validation_errors) {
                        state.errorValidateMessage = error.validation_errors;
                    }
                    if (error.message) {
                        state.errorValidateMessage = error;
                    }
                });
            }
            else if (invite_user_type.value == 2) {
                return organizationInteractor
                    .inviteComposition(email.value)
                    .then(function (result) {
                    emit('getListMembers');
                    emit('regist');
                    emit('close');
                }, function (error) {
                    if (error.validation_errors) {
                        state.errorValidateMessage = error.validation_errors;
                    }
                    if (error.message) {
                        state.errorValidateMessage = error;
                    }
                });
            }
            else if (invite_user_type.value == 0) {
                state.errorValidateMessage['notSelect'] = "担当者種別を選択してください";
            }
        });
        onMounted(function () {
            setValues({ invite_user_type: 0 });
        });
        return {
            state: state,
            errors: errors,
            values: values,
            onSubmit: onSubmit,
            isSubmitting: isSubmitting,
        };
    }
});
