import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, onMounted, reactive } from "vue";
import { useForm } from "vee-validate";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import Input from "@/presentation/components/input/Input.vue";
export default defineComponent({
    name: "OrganizationProfileReinviteMember",
    components: {
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "member"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var _b = useForm({}), handleSubmit = _b.handleSubmit, values = _b.values, errors = _b.errors, isSubmitting = _b.isSubmitting;
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            member: props.member,
        });
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var onSubmit = handleSubmit(function () {
            if (state.member.is_composition) {
                return organizationInteractor
                    .inviteComposition(state.member.email)
                    .then(function (result) {
                    emit('regist');
                    emit('getListMembers');
                    emit('close');
                }, function (error) {
                });
            }
            else {
                return organizationInteractor
                    .inviteMember(state.member.email)
                    .then(function (result) {
                    emit('regist');
                    emit('getListMembers');
                    emit('close');
                }, function (error) {
                });
            }
        });
        onMounted(function () {
        });
        return {
            state: state,
            errors: errors,
            values: values,
            onSubmit: onSubmit,
            isSubmitting: isSubmitting,
        };
    }
});
