import { reactive, defineComponent, onMounted } from "vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import TablePreload from "@/presentation/components/preloader/table-preload/TablePreload.vue";
import OrganizationProfileInviteMember from "@/presentation/components/modals/organization-profile-invite-member/OrganizationProfileInviteMember.vue";
import OrganizationProfileDeleteMember from "@/presentation/components/modals/organization-profile-delete-member/OrganizationProfileDeleteMember.vue";
import OrganizationProfileReinviteMember from "@/presentation/components/modals/organization-profile-reinvite-member/OrganizationProfileReinviteMember.vue";
import OrganizationChangeOwner from "@/presentation/components/modals/organization-change-owner/OrganizationChangeOwner.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
export default defineComponent({
    name: "OrganizationProfileMember",
    components: { TablePreload: TablePreload, OrganizationProfileInviteMember: OrganizationProfileInviteMember, OrganizationProfileDeleteMember: OrganizationProfileDeleteMember, OrganizationProfileReinviteMember: OrganizationProfileReinviteMember, OrganizationChangeOwner: OrganizationChangeOwner, Alert: Alert },
    props: ["organizationInfo", "breadcrumb"],
    setup: function (props) {
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var state = reactive({
            listMember: [],
            preload: true,
            showInvitePopup: false,
            showDeleteMemberPopup: false,
            showReinvitePopup: false,
            showChangeOwnerPopup: false,
            showPopupAlert: false,
            popupTitle: '',
            popupContent: '',
            deleteMember: {},
            reinviteMember: {},
            isOwner: false
        });
        var openEditModal = function () {
            state.showInvitePopup = true;
            state.popupTitle = "担当者を招待完了";
            state.popupContent = "担当者を招待完了しました";
        };
        var openDeleteMemberModal = function (member) {
            state.showDeleteMemberPopup = true;
            state.deleteMember = member;
            state.popupTitle = "担当者を削除完了";
            state.popupContent = "担当者を削除完了しました";
        };
        var openReinviteModal = function (member) {
            state.showReinvitePopup = true;
            state.reinviteMember = member;
            state.popupTitle = "担当者を再招待";
            state.popupContent = "担当者を再招待完了しました";
        };
        var openChangeOwnerModal = function () {
            state.showChangeOwnerPopup = true;
            state.reinviteMember = state.listMember[0];
            state.popupTitle = "オーナー変更";
            state.popupContent = "オーナー変更が完了しました";
        };
        function getListMembers() {
            organizationInteractor
                .getMembers()
                .then(function (result) {
                state.listMember = result.data;
                state.preload = false;
            })
                .catch(function (result) {
                state.preload = false;
            });
        }
        function checkIsOwner() {
            organizationInteractor.checkOwner(null).then(function (result) {
                if (result.data === true) {
                    state.isOwner = true;
                }
            }).catch(function (error) {
                state.preload = false;
            });
        }
        onMounted(function () {
            getListMembers();
            checkIsOwner();
            props.breadcrumb[2] = { name: "担当者一覧", route: "" };
        });
        return {
            state: state,
            props: props,
            openEditModal: openEditModal,
            getListMembers: getListMembers,
            openDeleteMemberModal: openDeleteMemberModal,
            openReinviteModal: openReinviteModal,
            openChangeOwnerModal: openChangeOwnerModal
        };
    }
});
