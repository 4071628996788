<template>
  <div class="member">
    <TablePreload v-if="state.preload" :row="10"/>
    <div v-if="!state.preload">
      <div class="member__title">
        <p>担当者一覧</p>
        <div>
          <button v-if="state.isOwner" class="btn-sm btn-blue btn-change-owner" @click="openChangeOwnerModal()">
          オーナー変更
        </button>
        <button class="btn-sm btn-blue" @click="openEditModal()">
          担当者を招待
        </button>
        </div>
      </div>
      <span class="note">※ ●は地域ハブ団体担当者です。</span>
      <div class="member__list">
        <div class="table">
          <table>
            <thead>
            <tr>
              <th></th>
              <th>氏名</th>
              <th>氏名(カナ)</th>
              <th>メールアドレス</th>
              <th>招待日時</th>
              <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="member in state.listMember" v-bind:key="member.id">
              <td class="column_name">
              <span class="note" v-if="member.is_composition">●</span>
              <span class="organization_owner" v-if="member.is_owner">オーナー</span>
                </td>
              <td class="column_name">
                {{ member.family_name }} {{ member.given_name }}
              </td>
              <td class="column_name">
                {{ member.family_name_kana }} {{ member.given_name_kana }}
              </td>
              <td class="column_name">
                {{ member.email }}
              </td>
              <td class="column_name" v-if = "!member.is_composition">
                <template v-if="member.invited_date">
                  {{ member.is_available ? "承認済" : member.invited_date }}
                </template>
              </td>
              <td class="column_name" v-if = "member.is_composition">
                <template v-if="member.is_accepted && member.is_available">
                  承認済
                </template>
                <template v-else>
                  {{ member.invited_date ? member.invited_date : "" }}
                </template>
              </td>
              <td class="column_name">
                <template v-if="(member.invited_date && !member.is_available) || !member.is_accepted" >
                  <button class="btn-xs btn-blue" @click="openReinviteModal(member)">再招待</button>
                </template>
                <template v-else-if="member.email != props.organizationInfo.email && !member.is_owner">
                  <button class="btn-xs btn-red" @click="openDeleteMemberModal(member)">削除</button>
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <OrganizationProfileInviteMember
          v-if="state.showInvitePopup"
          @close="state.showInvitePopup = false;"
          @regist="state.showAlertPopup = true"
          @getListMembers="getListMembers"
      />
      <OrganizationProfileDeleteMember
          v-if="state.showDeleteMemberPopup"
          :member="state.deleteMember"
          @close="state.showDeleteMemberPopup = false;"
          @regist="state.showAlertPopup = true"
          @getListMembers="getListMembers"
      />
      <OrganizationProfileReinviteMember
          v-if="state.showReinvitePopup"
          :member="state.reinviteMember"
          @close="state.showReinvitePopup = false;"
          @regist="state.showAlertPopup = true"
          @getListMembers="getListMembers"
      />
      <OrganizationChangeOwner
          v-if="state.showChangeOwnerPopup"
          :members="state.listMember"
          :isOwner="state.isOwner"
          @close="state.showChangeOwnerPopup = false;"
          @regist="state.showAlertPopup = true"
          @getListMembers="getListMembers"
          @checkIsOwner="state.isOwner = false"
      />
      <Alert v-if="state.showAlertPopup" @close="state.showAlertPopup = false" :content="state.popupContent" :title="state.popupTitle"/>
    </div>
  </div>
</template>
<script lang="ts" src="@/presentation/views/organization/my-profile/member/Member.ts" />
<style lang="scss" src="@/presentation/views/organization/my-profile/member/Member.scss" scoped></style>
