import LanguageUtil from "@/commons/LanguageUtil";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import OrganizationInteractor from "@/domain/usecases/OrganizationInteractor";
import DIContainer from "@/core/DIContainer";
import Input from "@/presentation/components/input/Input.vue";
export default defineComponent({
    name: "OrganizationChangeOwner",
    components: {
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "members",
        "isOwner"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var _b = useForm({}), handleSubmit = _b.handleSubmit, values = _b.values, errors = _b.errors, isSubmitting = _b.isSubmitting, setValues = _b.setValues;
        var ownerCandidateMember = computed(function () {
            return props.members.filter(function (row) {
                return !row.is_composition
                    && row.is_available
                    && !row.is_owner;
            });
        });
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            members: props.members,
            errorValidateMessage: {},
        });
        var new_owner_id = useField("new_owner_id", yup.number().nullable()).value;
        var organizationInteractor = DIContainer.instance.get(OrganizationInteractor);
        var onSubmit = handleSubmit(function () {
            return organizationInteractor
                .changeOrganizationOwner(values.new_owner_id)
                .then(function (result) {
                emit('regist');
                emit('getListMembers');
                emit('checkIsOwner');
                emit('close');
            }, function (error) {
                state.errorValidateMessage = error.validation_errors;
            });
        });
        onMounted(function () {
            setValues({ new_owner_id: "" });
        });
        return {
            state: state,
            errors: errors,
            values: values,
            onSubmit: onSubmit,
            ownerCandidateMember: ownerCandidateMember,
            isSubmitting: isSubmitting,
        };
    }
});
