
<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="profile_invite__edit__container">
                <div class="profile_invite__edit__container-title">担当者を招待</div>
                <form class="horizontal" @submit="onSubmit">
                  <!-- email -->
                  <div class="form-ctrl">
                    <label class="text organization-select" >
                      担当者種別
                      <br />
                    </label>
                     <div class="form-group">
                      <select
                          v-model="values.invite_user_type"
                          :class="{ invalid: !values.invite_user_type }"
                          class="select"
                      >
                          <option :value=0 selected disabled>お選びください</option>
                          <option :value=1>企業担当者</option>
                          <option :value=2>地域ハブ団体</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-ctrl">
                    <label class="text">
                      メールアドレス
                      <br />
                      <span class="note">※公開されません</span>
                    </label>
                    <div class="form-group">
                      <Input
                        maxlength="255"
                        id="email"
                        type="email"
                        name="email"
                        v-model="values.email"
                        placeholder="ご入力ください"
                      />
                      <ErrorMessage v-if="errors.email" :message="errors.email" />
                      <template
                        v-if="state.errorValidateMessage.email"
                      >
                        <ErrorMessage
                          v-for="m of state.errorValidateMessage.email"
                          :key="m"
                          :message="m"
                        >
                        </ErrorMessage>
                      </template>
                      <template
                        v-if="state.errorValidateMessage"
                      >
                        <ErrorMessage
                          v-if="state.errorValidateMessage['message']"
                          :message="state.errorValidateMessage['message']"
                        >
                        </ErrorMessage>
                      </template>
                      <template
                        v-if="state.errorValidateMessage['notSelect']"
                      >
                        <ErrorMessage
                          v-if="state.errorValidateMessage['notSelect']"
                          :message="state.errorValidateMessage['notSelect']"
                        >
                        </ErrorMessage>
                      </template>
                    </div>
                  </div>
                  <!-- profile_ai_description -->
                  <div class="profile_invite__edit__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md"
                    >キャンセル</button>
                    <button
                      :disabled="isSubmitting"
                      :class="{ submitting: isSubmitting }"
                      type="submit"
                      class="btn-blue btn-md"
                    >
                      <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                      招待する
                    </button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/organization-profile-invite-member/OrganizationProfileInviteMember.ts" />
<link type="scss" scoped src="@/presentation/components/modals/organization-profile-invite-member/OrganizationProfileInviteMember.scss" />
